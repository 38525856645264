<template lang="pug">
	header#header
		.group
			button(@click="toggleMenu()")
				FontAwesomeIcon(:icon="icons.faBars")

			Logo
		button(@click="logout").logout
			FontAwesomeIcon(:icon="icons.faRightFromBracket")
</template>

<script>
import Logo from '@components/Logo/Logo'
import { faRightFromBracket, faBars } from '@fortawesome/free-solid-svg-icons'

export default {
	name: 'component-header',
	components: {
		Logo
	},
	data() {
		return {
			icons: {
				faRightFromBracket,
				faBars
			}
		}
	},
	methods: {
		logout(){
			if(localStorage.getItem('TOKEN')){
				localStorage.removeItem('TOKEN')
				localStorage.removeItem('USER_NAME')
				localStorage.removeItem('USER_ID')
				this.$axios.defaults.headers.Authorization = ''
				this.$router.push({name: 'Home'})
			}
		},
		toggleMenu() {
			this.$store.state.sideMenu = !this.$store.state.sideMenu
		}
	}
}
</script>

<style lang="stylus" scoped src="./Header.styl"></style>
