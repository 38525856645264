<template lang="pug">
	aside#menu(:class="{'ativo': $store.state.sideMenu}")
		ul
			li
				router-link(:to="{name: 'Painel'}")
					FontAwesomeIcon(:icon="icons.faChartLine")
					span Dashboard
			li
				router-link(:to="{name: 'Coupons'}")
					FontAwesomeIcon(:icon="icons.faTag")
					span Cupons
			li
				router-link(:to="{name: 'Register'}")
					FontAwesomeIcon(:icon="icons.faUserPlus")
					span Cadastrar Usuário
</template>

<script>
import { faUserPlus, faChartLine, faTag } from '@fortawesome/free-solid-svg-icons'

export default {
	name: "component-menu",
	data() {
		return {
			icons: {
				faUserPlus,
				faChartLine,
				faTag
			}
		}
	}
}
</script>

<style lang="stylus" scoped src="./Menu.styl"></style>
