<template lang="pug">
	section#container
		Header
		.page
			Menu
			slot
</template>

<script>
import Header from '@components/Header/Header'
import Menu from '@components/Menu/Menu'

export default {
	name: "component-container",
	components: {
		Header,
		Menu
	},
}
</script>

<style lang="stylus" scoped src="./Container.styl"></style>
