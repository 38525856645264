import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=5986fb80&scoped=true&lang=pug&"
import script from "./Container.vue?vue&type=script&lang=js&"
export * from "./Container.vue?vue&type=script&lang=js&"
import style0 from "./Container.styl?vue&type=style&index=0&id=5986fb80&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5986fb80",
  null
  
)

export default component.exports